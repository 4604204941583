const MapDescriptor = require("./Map");
const ReduceDescriptor = require("./Reduce");
const FilterDescriptor = require("./Filter");
const MatchFilterDescriptor = require("./MatchFilter");
const BatcherDescriptor = require("./Batcher");
const TimerDescriptor = require("./Timer");
const MonitorDescriptor = require("./Monitor");
const BufferDescriptor = require("./Buffer");

const { parseExpression, evalExpression } = require("./eval");

// TODO: do we need this entry point at all?

function createServiceFactory(rt, descriptor) {
  const { serviceId, serviceName, service: Service } = descriptor;
  return {
    serviceId,
    serviceName,
    create: (board, descriptor, id) => new Service(rt, board, descriptor, id),
  };
}

function createRegistry(rt) {
  return [
    createServiceFactory(rt, MapDescriptor),
    createServiceFactory(rt, ReduceDescriptor),
    createServiceFactory(rt, MatchFilterDescriptor),
    createServiceFactory(rt, FilterDescriptor),
    createServiceFactory(rt, TimerDescriptor),
    createServiceFactory(rt, BatcherDescriptor),
    createServiceFactory(rt, MonitorDescriptor),
  ];
}

module.exports = {
  parseExpression,
  evalExpression,
  createRegistry,
  MapDescriptor,
  ReduceDescriptor,
  FilterDescriptor,
  MatchFilterDescriptor,
  BatcherDescriptor,
  TimerDescriptor,
  MonitorDescriptor,
  BufferDescriptor,
};
