const { parseExpression, evalExpression } = require("./eval");

const serviceId = "hookup.to/service/map";
const serviceName = "Map";

function deepCopy(x) {
  return JSON.parse(JSON.stringify(x));
}

function merge(dst, value, path) {
  path.split(".").reduce((branch, key, idx, src) => {
    const isLastKey = idx + 1 >= src.length;
    if (branch[key] !== undefined) {
      return branch[key];
    }
    return (branch[key] = isLastKey ? value : {});
  }, dst);
  return dst;
}

function asNestedValue(value, path) {
  return path
    .split(".")
    .reverse()
    .reduce((o, k) => (o ? { [k]: o } : { [k]: value }), undefined);
}

class Map {
  constructor(app, board, descriptor, id) {
    this.uuid = id;
    this.board = board;
    this.app = app;

    this.mode = "replace";
  }

  configure(config) {
    const { template, condition, mode } = config;
    if (template !== undefined) {
      this._terms = {};
      this.template = template;
      this._properties = Object.keys(template).reduce((all, cur) => {
        const value = template[cur];
        if (cur[cur.length - 1] === "=") {
          // treated as a dynamic term not a static property
          this._terms[cur.substr(0, cur.length - 1)] = parseExpression(value);
          return all;
        } else {
          return cur.indexOf(".") !== -1
            ? { ...merge(all, value, cur) }
            : { ...all, [cur]: value };
        }
      }, {});
      this.app.notify(this, { template });
    }

    if (condition !== undefined) {
      this.condition = parseExpression(condition);
    }

    if (mode !== undefined) {
      this.mode = mode;
      this.app.notify(this, { mode });
    }
  }

  process = (params) => {
    const mapper = (x) => {
      if (this.condition) {
        if (!evalExpression(this.condition, { params: x })) {
          return x; // no map when condition present and failing
        }
      }
      try {
        const keys = Object.keys(this._terms);
        // map to scalar value (not an object) if only an '=', i.e. empty string
        if (keys.length === 1 && keys[0] === "") {
          return evalExpression(this._terms[keys[0]], { params: x });
        }

        const initial =
          this.mode === "replace"
            ? deepCopy(this._properties)
            : { ...x, ...deepCopy(this._properties) };
        return keys.reduce((p, key) => {
          const y = evalExpression(this._terms[key], { params: x });
          return key.indexOf(".") !== -1
            ? {
                ...merge(p, y, key),
              }
            : {
                ...p,
                [key]: y,
              };
        }, initial);
      } catch (error) {
        return x; // do not map the input - there was an error
      }
    };

    if (Array.isArray(params)) {
      return params.map(mapper);
    }

    return mapper(params);
  };
}

module.exports = {
  serviceName,
  serviceId,
  service: Map,
  Map,
};
