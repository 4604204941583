const serviceId = 'hookup.to/service/buffer';
const serviceName = 'Buffer';

class Buffer {
  constructor(app, board, descriptor, id) {
    this.uuid = id;
    this.board = board;
    this.app = app;

    this.buffer = [];
    this.capacity = 100;
  }

  configure(config) {
    const { 
      capacity, 
      action 
    } = config;

    if (capacity !== undefined) {
      this.capacity = capacity;
      this.app.notify(this, { type: 'capacity' });
    }

    if (action !== undefined) {
      switch (action) {
        case 'getBuffer':
          return this.buffer;
        default:
          console.error('Unknown buffer action', action);
      }
    }
  }

  process(params) {
    this.buffer = this.buffer.concat(params);
    if (this.buffer.length > this.capacity) {
      const diff = this.buffer.length - this.capacity;
      this.buffer = this.buffer.slice(diff);
    }
    this.app.notify(this, { type: 'updated' });
    return this.buffer;
  }
}

module.exports = {
  serviceName,
  serviceId,
  service: Buffer,
  Buffer,
};
