import React from "react";
import ReactDOM from "react-dom";

import Peerjs from "peerjs";
import { Observable } from "rxjs";

import "semantic-ui-css/semantic.min.css";

import Headless from "hkp-frontend/src/views/headless";

const params = new URLSearchParams(window.location.search);

const emptyFunc = () => {};

function to(
  name,
  {
    host = params.get("peersHost") || "peers.hookup.to",
    onPeer = emptyFunc,
    onData = emptyFunc,
  }
) {
  const peer = new Peerjs(name, {
    host,
    port: 443,
    path: "/",
    secure: true,
  });

  peer.on("open", () => {
    onPeer(name, peer);
  });

  peer.on("connection", (connection) =>
    connection.on("data", (data) => onData(data, connection.peer))
  );
}

function buildRuntime(props, rootElement) {
  const root = rootElement ? rootElement : document.createElement("div");
  if (!rootElement) {
    document.body.appendChild(root);
  }

  let subscribers = [];
  const onDataCallback = (data) => {
    for (const sub of subscribers) {
      sub.next(data);
    }
  };
  let headless;
  const Comp = (
    <Headless
      {...props}
      ref={(h) => (headless = h)}
      callback={onDataCallback}
    />
  );
  ReactDOM.render(Comp, root);

  const obs = new Observable((subscriber) => {
    subscribers.push(subscriber);
  });
  const subscribe = (callbacks) => {
    const subscription = obs.subscribe(callbacks);
    const theSubscriber = subscribers[subscribers.length - 1];
    return {
      unsubscribe: () => {
        subscribers = subscribers.filter((s) => s !== theSubscriber);
        subscription.unsubscribe();
      },
    };
  };

  const configure = (serviceId, config) =>
    headless.configure(serviceId, config);

  const process = (params, serviceId) => headless.process(params, serviceId);
  const processService = (serviceId, params) =>
    headless.processService(serviceId, params);

  const configuration = (serviceId) => headless.configuration(serviceId);

  return {
    root,
    subscribe,
    configure,
    configuration,
    process,
    processService,
  };
}

const hookup = { to, buildRuntime, version: Headless.buildVersion };

window.hookup = hookup;
export { hookup };
