const { parse, eval: expEval } = require("expression-eval");
const moment = require("moment");

function evilEval(f, params) {
  const script = `'use strict'; const params=${JSON.stringify(
    params
  )}; return (${f})`;
  return Function(script)();
}

function reformatDate(date, inputFormat, outputFormat) {
  return moment(date, inputFormat).format(outputFormat);
}

function parseExpression(f) {
  try {
    return parse(f);
  } catch (err) {
    return "syntax-error";
  }
}

function evalExpression(ast, params) {
  if (ast === "syntax-error") {
    return "syntax-error";
  }
  const math = {
    print: console.log,
    round: Math.round,
    sin: Math.sin,
    min: Math.min,
    max: Math.max,
    rand: Math.random,
    number: (x) => Number(x),
    string: (x) => `${x}`,
    stringify: JSON.stringify,
    parse: JSON.parse,
    concat: (x, y) => `${x}${y}`,
    reformatDate,
    blobToUint8Array: async (x) => {
      const buffer = await x.arrayBuffer();
      return new Uint8Array(buffer);
    },
    sum: (x) => x.reduce((acc, v) => acc + v, 0),
    avg: (x) => x.reduce((acc, v) => acc + v, 0) / x.length,
  };
  return expEval(ast, { ...params, ...math });
}

module.exports = {
  evilEval,
  parseExpression,
  evalExpression,
};
